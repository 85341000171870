import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TicketSubjects from './components/TicketSubjects';

const ticketSubjects = (props,{ t }) => (
  <Container>
    <TicketSubjects {...props} />
  </Container>
);

ticketSubjects.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ticketSubjects);
