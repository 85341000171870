import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const MissingItems = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("MissingItems");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  // componentDidMount = () => {
  //   //Screen permisions value can be edit,view, no view
  //   let screenPermissions = RolePermissions.screenPermissions('Test');
  //   if (screenPermissions) {
  //     this.setState({
  //       rolePermission: screenPermissions
  //     })
  //   }
  //   // this.getRoleListFromServer()
  // }
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        type: "relateAutoComplete",
        placeholder: "Facility",
        label: "Facility",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Facility",
        derivedValue: "facility=facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        searchField: "facilityName",
        fieldType: "relateAutoComplete",
        displayFields: [],
        controllerName: "Facilities",
        searchApi: "facilities",
        textAlign: "Center",
        show: true,
        field: "facility",
        showOrHideFields: [],
        fieldName: "facility",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "cabinents",
        type: "relateAutoComplete",
        placeholder: "Cabinents",
        label: "Cabinents",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Cabinents",
        derivedValue: "cabinents=cabinents=cabinents=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "cabinents",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "cabinet",
        hasDependency: "false",
        searchField: "cabinetName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["cabinetName"],
        controllerName: "cabinet",
        searchApi: "cabinets",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "cabinents",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "racks",
        type: "relateAutoComplete",
        placeholder: "Racks",
        label: "Racks",
        width: "150px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Racks",
        derivedValue: "racks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "racks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1072,
        searchField: "rackName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["rackName"],
        controllerName: "racks",
        searchApi: "racks",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "racks",
        showOrHideFields: [],
        fieldName: "racks",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "productName",
        type: "relateAutoComplete",
        placeholder: "Product Name",
        label: "Product Name",
        width: "150px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Product Name",
        derivedValue: "productName=productName=productName=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "productName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: "Products",
        searchField: "productName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: ["productName"],
        controllerName: "Products",
        searchApi: "products",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "productName",
        showOrHideFields: [],
        fieldName: "productName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "current Inventory",
        type: "text",
        placeholder: "Current Inventory",
        label: "Current Inventory",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Current Inventory",
        derivedValue: "currentInventory=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "currentInventory",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "productQuantityBeingAdded",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "requiredInventory",
        type: "text",
        placeholder: "Required Inventory",
        label: "Required Inventory",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Required Inventory",
        derivedValue: "requiredInventory=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "requiredInventory",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "requiredInventory",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "needToOrder",
        type: "text",
        placeholder: "Need To Order",
        label: "Need To Order",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Need To Order",
        derivedValue: "needToOrder=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "needToOrder",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "needToOrder",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "description=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "volume",
        type: "text",
        placeholder: "Volume",
        label: "Volume",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "volume=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "volume",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "currentInvenotry",
        type: "text",
        placeholder: "Current Invenotry",
        label: "Current Invenotry",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue:
          "currentInvenotry=currentInvenotry=expirationDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "currentInvenotry",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]$",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: false,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "requiredInventory",
        type: "text",
        placeholder: "Required Inventory",
        label: "Required Inventory",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "requiredInventory=storageUnitSubLocations=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "requiredInventory",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "NeedToOrder",
        type: "text",
        placeholder: "Need To Order",
        label: "Need To Order",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "NeedToOrder=productQuantityBeingAdded=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "NeedToOrder",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "facility",
        type: "text",
        placeholder: "Facility",
        label: "Facility",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "storageUnit",
        type: "text",
        placeholder: "Storage Unit",
        label: "Storage Unit",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "storageUnit=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "storageUnit",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "storageUnit",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lotId",
        type: "text",
        placeholder: "Lot Id",
        label: "Lot Id",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "lotId=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^.{0,10000}$",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: false,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: false,
          showNumField: false,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "created=created=created=created=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "updated=updated=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "volume",
        type: "text",
        placeholder: "Volume",
        label: "Volume",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Volume",
        derivedValue: "volume=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "volume",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "volume",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "currentInvenotry",
        type: "text",
        placeholder: "Current Invenotry",
        label: "Current Invenotry",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Current Invenotry",
        derivedValue:
          "currentInvenotry=currentInvenotry=expirationDate=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "currentInvenotry",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]$",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: false,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "currentInvenotry",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "requiredInventory",
        type: "text",
        placeholder: "Required Inventory",
        label: "Required Inventory",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Required Inventory",
        derivedValue: "requiredInventory=storageUnitSubLocations=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "requiredInventory",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "requiredInventory",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "NeedToOrder",
        type: "text",
        placeholder: "Need To Order",
        label: "Need To Order",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Need To Order",
        derivedValue: "NeedToOrder=productQuantityBeingAdded=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "NeedToOrder",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "NeedToOrder",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facility",
        type: "text",
        placeholder: "Facility",
        label: "Facility",
        width: "130px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Facility",
        derivedValue: "facility=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "facility",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "facility",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "storageUnit",
        type: "text",
        placeholder: "Storage Unit",
        label: "Storage Unit",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Storage Unit",
        derivedValue: "storageUnit=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "storageUnit",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "storageUnit",
        showOrHideFields: [],
        fieldName: "storageUnit",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "lotId",
        type: "text",
        placeholder: "Lot Id",
        label: "Lot Id",
        width: "130px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Lot Id",
        derivedValue: "lotId=undefined",
        capitalizeTableText: true,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "lotId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^.{0,10000}$",
        regexData: {
          showMinField: false,
          minLengthRequired: false,
          minLengthText: null,
          showMaxField: false,
          maxLengthRequired: false,
          maxLengthText: null,
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: false,
          showNumField: false,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: true,
        field: "lotId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Created",
        derivedValue: "created=created=created=created=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Updated",
        derivedValue: "updated=updated=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };
  // submit1 = item => {
  //   let x = missingItemsFields
  //   let objmissingItems = {}

  //   objmissingItems["_id"] = item[0]["_id"]
  //   for (let x2 of x) {
  //     objmissingItems[x2] = item[0][x2]
  //   }
  //   let formFields = this.getFormFields()

  //   formFields = formFields.filter(y => x.includes(y.name))

  //   this.setState({
  //     openNewUserModal: true,
  //     item: objmissingItems,
  //     newFormFields: formFields
  //   });
  // }

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    // this.setState({
    //   isLoading: true
    // });
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.missingItems}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired === "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
          // this.setState({
          //   isLoading: false
          // });
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          // globalSearch={'Display Name/Email'}
          // type='MissingItems'
          // apiUrl={apiCalls.MissingItems}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },

            // {
            //   'name': 'Block',
            //   "options": [
            //     { 'label': 'Block', 'value': 'Block', 'show': true, "multiple": false, }
            //   ]
            // },
            // {
            //   'name': 'ResetPassword',
            //   "options": [
            //     { 'label': 'ResetPassword', 'value': 'ResetPassword', 'show': true, "multiple": false, }
            //   ]
            // },
            // {
            //   'name': 'Submit',
            //   'action': this.submit,
            //   "options": [
            //     { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
            //   ]
            // },
            // {
            //   name: "newModel",
            //   action: this.submit1,
            //   options: [
            //     { label: "newModel", value: "newModel", show: true, multiple: false }
            //   ]
            // }
          ]}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.missingItems}
          globalSearch={
            "description/volume/currentInvenotry/requiredInventory/NeedToOrder/facility/storageUnit/lotId/created/updated"
          }
          displayName="Missing Items"
          type="MissingItems"
          routeTo={apiCalls.missingItems}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.missingItems.toLowerCase()}
          apiUrl={apiCalls.missingItems}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="missingItems"
          apiUrl={apiCalls.missingItems}
        />
      ) : null}
    </span>
  );
};

export default MissingItems;
