import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

const Piechart = (props) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        let chartOptions = props.chartOptions;
        console.log('chartOptions', chartOptions)
        let labels = [];
        let values = [];
        let colors = [];

        if(chartOptions && chartOptions.length > 0){
            chartOptions.map( option => {
                labels.push(option.label);
                values.push(option.value);
                colors.push(option.color);
            })
        }
        const data = {
            labels: labels,
            datasets: [
                {
                    data: values,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors
                }
            ]
        };
        const options = {
            cutout: '60%'
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className="w-100">
            <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full" />
        </div>
    )
        
}

export default Piechart;