const commonSampleFilePath = "http://localhost:3000/images/sample";

const configSampleFiles = {
  employees:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_employees_bulk_upload_file.csv",
  activities:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_activities_bulk_upload_file.csv",
  emailtemplates:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_emailtemplates_bulk_upload_file.csv",
  roles:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_roles_bulk_upload_file.csv",
  uploadhistories:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_uploadhistories_bulk_upload_file.csv",
  emailstatuses:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_emailstatuses_bulk_upload_file.csv",
  facilities:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_facilities_bulk_upload_file.csv",
  cabinets:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_cabinets_bulk_upload_file.csv",
  mininventorybystates:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_mininventorybystates_bulk_upload_file.csv",
  categories:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_categories_bulk_upload_file.csv",
  products:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_products_bulk_upload_file.csv",
  storageunititems:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_storageunititems_bulk_upload_file.csv",
  productquantities:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_productquantities_bulk_upload_file.csv",
  expirationitems:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_expirationitems_bulk_upload_file.csv",
  expireditems:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_expireditems_bulk_upload_file.csv",
  missingitemscounts:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_missingitemscounts_bulk_upload_file.csv",
  missingitems:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_missingitems_bulk_upload_file.csv",
  recallitems:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_recallitems_bulk_upload_file.csv",
  itemsrecalleds:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_itemsrecalleds_bulk_upload_file.csv",
  recalls:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_recalls_bulk_upload_file.csv",
  facilitystaffs:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_facilitystaffs_bulk_upload_file.csv",
  orders:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_orders_bulk_upload_file.csv",
  placedorders:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_placedorders_bulk_upload_file.csv",
  fulfilledorders:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_fulfilledorders_bulk_upload_file.csv",
  requiredinventories:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_requiredinventories_bulk_upload_file.csv",
  mininventorybyproducts:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_mininventorybyproducts_bulk_upload_file.csv",
  distributors:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_distributors_bulk_upload_file.csv",
  helps:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_helps_bulk_upload_file.csv",
  companyusers:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_companyusers_bulk_upload_file.csv",
  alerts:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_alerts_bulk_upload_file.csv",
  notifications:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_notifications_bulk_upload_file.csv",
  payments:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_payments_bulk_upload_file.csv",
  invoices:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_invoices_bulk_upload_file.csv",
  tickets:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_tickets_bulk_upload_file.csv",
  ticketsubjects:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_ticketsubjects_bulk_upload_file.csv",
  cartlists:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_cartlists_bulk_upload_file.csv",
  racks:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_racks_bulk_upload_file.csv",
  inventries:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_inventries_bulk_upload_file.csv",
  mantainances:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_mantainances_bulk_upload_file.csv",
  subcategories:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_subcategories_bulk_upload_file.csv",
  categorytypes:
    "https://api.assetmanagement.dosystemsinc.com/images/sample_categorytypes_bulk_upload_file.csv",
};
export default configSampleFiles;
