const apiCalls = {
  loginUser: "auth/login",
  logoutUser: "auth/logout",
  loginChangePassword: "auth/loginChangePassword",
  MenuList: "menus",
  Settings: "settings",
  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  dashboard: "dashboard/counts",
  Uploads: "bulkuploadStatus",
  employees: "employees",
  facilities: "facilities",
  cabinets: "cabinets",
  minInventoryByStates: "minInventoryByStates",
  categories: "categories",
  products: "products",
  storageUnitItems: "storageUnitItems",
  productQuantities: "productQuantities",
  expirationItems: "expirationItems",
  expiredItems: "productQuantities",
  missingItemsCounts: "missingItemsCounts",
  missingItems: "productQuantities",
  recallItems: "recallItems",
  itemsRecalleds: "itemsRecalleds",
  recalls: "recalls",
  facilityStaffs: "facilityStaffs",
  orders: "orders",
  placedOrders: "orders",
  fulfilledOrders: "orders",
  requiredInventories: "requiredInventories",
  minInventoryByProducts: "minInventoryByProducts",
  distributors: "distributors",
  helps: "helps",
  companyUsers: "companyUsers",
  alerts: "alerts",
  notifications: "notifications",
  payments: "payments",
  invoices: "invoices",
  tickets: "tickets",
  ticketSubjects: "ticketSubjects",
  cartLists: "cartLists",
  racks: "racks",
  inventries: "inventries",
  mantainances: "mantainances",
  subcategories: "subcategories",
  categoryTypes: "categoryTypes",
};

// {"loginUser":"auth/login","logoutUser":"auth/logout","loginChangePassword":"auth/loginChangePassword","MenuList":"menus","Settings":"settings","LocationImagePath":"uploads?uploadWhileCreate=true&uploadPath=location","ProfileImagePath":"uploads?uploadWhileCreate=true&uploadPath=employee","dashboard":"dashboard/counts","employee":"employees","employees":"employees","Uploads":"bulkuploadStatus"}

export default apiCalls;
