import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Facilities = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Facilities");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };
  // componentDidMount = () => {
  //   //Screen permisions value can be edit,view, no view
  //   let screenPermissions = RolePermissions.screenPermissions('Test');
  //   if (screenPermissions) {
  //     this.setState({
  //       rolePermission: screenPermissions
  //     })
  //   }
  //   // this.getRoleListFromServer()
  // }
  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facilityName",
        type: "text",
        placeholder: "Facility Name",
        label: "Facility Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Facility Name",
        derivedValue: "facilityName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: false,
        id: "facilityName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "facilityName",
        showOrHideFields: [],
        fieldName: "facilityName",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "facilityEmail",
        type: "email",
        placeholder: "Facility Email",
        label: "Facility Email",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Facility Email",
        derivedValue: "facilityEmail=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "facilityEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "facilityEmail",
        showOrHideFields: [],
        fieldName: "facilityEmail",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "derivedFields",
        placeholder: "Address",
        label: "Address",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: "string",
        header: "Address",
        derivedValue: "address=street+city+state+zip",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: ["street", "city", "state", "zip"],
        actionsNumber: [],
        id: "address",
        displayinaddForm: false,
        displayineditForm: false,
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        showOrHideFields: [],
        fieldName: "address",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "country",
        type: "text",
        placeholder: "Country",
        label: "Country",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Country",
        derivedValue: "country=street=street=street=street=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "country",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "country",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "text",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "State",
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zip",
        type: "text",
        placeholder: "Zip",
        label: "Zip",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Zip",
        derivedValue: "zip=zip=zip=zip=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zip",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{1,1000000}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "1",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "1000000",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: false,
        field: "zip",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        width: "200px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Address",
        derivedValue: "address=addressLine1=addressLine1=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "addressLine2",
        type: "textarea",
        placeholder: "Address Line2",
        label: "Address Line2",
        width: "200px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Address Line2",
        derivedValue: "addressLine2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "addressLine2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "addressLine2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfCabinets",
        type: "number",
        placeholder: "Number Of Cabinets",
        label: "Number Of Cabinets",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Number Of Cabinets",
        derivedValue:
          "numberOfCabinets=numberOfCabinets=numberofcabinets=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "numberOfCabinets",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "numberOfCabinets",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'redirectable',
        redirecTo : 'cabinets',
        redirectFilterField : 'facility',
      },
      {
        name: "numberOfRacks",
        type: "number",
        placeholder: "Number Of Racks",
        label: "Number Of Racks",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Number Of Racks",
        derivedValue: "numberOfRacks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "numberOfRacks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "numberOfRacks",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
        fieldType : 'redirectable',
        redirecTo : 'racks',
        redirectFilterField : 'facility',
      },
      {
        name: "weeklyNotifiedOn",
        type: "dropDown",
        placeholder: "Select Day",
        label: "Weekly Notified On",
        width: "130px",
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Weekly Notified On",
        derivedValue: "weeklyNotifiedOn=weeklyNotifiedOn=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "weeklyNotifiedOn",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Sunday", value: "Sunday", color: "primary" },
          { label: "Monday", value: "Monday", color: "primary" },
          { label: "Tuesday", value: "Tuesday", color: "primary" },
          { label: "Wednesday", value: "Wednesday", color: "primary" },
          { label: "Thursday", value: "Thursday", color: "primary" },
          { label: "Friday", value: "Friday", color: "primary" },
          { label: "Saturday", value: "Saturday", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: false,
        field: "weeklyNotifiedOn",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "facilityName",
        type: "text",
        placeholder: "Facility Name",
        label: "Facility Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "facilityName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: false,
        id: "facilityName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "facilityName",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "facilityEmail",
        type: "email",
        placeholder: "Facility Email",
        label: "Facility Email",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "facilityEmail=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "facilityEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "facilityEmail",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        derivedValue: "created=created=created=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "updated=updated=updated=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "country",
        type: "text",
        placeholder: "City",
        label: "city",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "country=street=street=street=street=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "state",
        type: "text",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "zip",
        type: "text",
        placeholder: "Zip",
        label: "Zip",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "zip=zip=zip=zip=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zip",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{1,1000000}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "1",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "1000000",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "200px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "address=addressLine1=addressLine1=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "addressLine2",
        type: "text",
        placeholder: "Address Line2",
        label: "Address Line2",
        width: "200px",
        addFormOrder: 4,
        editFormOrder: 4,
        derivedValue: "addressLine2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "addressLine2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "numberOfCabinets",
        type: "number",
        placeholder: "How many Cabinets per a Facility",
        label: "How many Cabinets per a Facility",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue:
          "numberOfCabinets=numberOfCabinets=numberofcabinets=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "numberOfCabinets",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "numberOfRacks",
        type: "number",
        placeholder: "How many racks per a cabinet",
        label: "How many racks per a cabinet",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "numberOfRacks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "numberOfRacks",
        displayinaddForm: "true",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: true,
      },
      {
        name: "weeklyNotifiedOn",
        type: "dropDown",
        placeholder: "Select Day",
        label: "Weekly Notified On",
        width: "130px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "weeklyNotifiedOn=weeklyNotifiedOn=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "weeklyNotifiedOn",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Sunday", value: "Sunday", color: "primary" },
          { label: "Monday", value: "Monday", color: "primary" },
          { label: "Tuesday", value: "Tuesday", color: "primary" },
          { label: "Wednesday", value: "Wednesday", color: "primary" },
          { label: "Thursday", value: "Thursday", color: "primary" },
          { label: "Friday", value: "Friday", color: "primary" },
          { label: "Saturday", value: "Saturday", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "facilityName",
        type: "text",
        placeholder: "Facility Name",
        label: "Facility Name",
        width: 110,
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Facility Name",
        derivedValue: "facilityName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: false,
        id: "facilityName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "facilityName",
        showOrHideFields: [],
        fieldName: "facilityName",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "facilityEmail",
        type: "email",
        placeholder: "Facility Email",
        label: "Facility Email",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Facility Email",
        derivedValue: "facilityEmail=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "facilityEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "facilityEmail",
        showOrHideFields: [],
        fieldName: "facilityEmail",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "derivedFields",
        placeholder: "Address",
        label: "Address",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: "string",
        header: "Address",
        derivedValue: "address=street+city+state+zip",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: ["street", "city", "state", "zip"],
        actionsNumber: [],
        id: "address",
        displayinaddForm: false,
        displayineditForm: false,
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        showOrHideFields: [],
        fieldName: "address",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        addFormOrder: 12,
        editFormOrder: 12,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "country",
        type: "text",
        placeholder: "Country",
        label: "Country",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Country",
        derivedValue: "country=street=street=street=street=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "country",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "country",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "text",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "State",
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: false,
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zip",
        type: "text",
        placeholder: "Zip",
        label: "Zip",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Zip",
        derivedValue: "zip=zip=zip=zip=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zip",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        validationRequired: true,
        regexPattern: "^[0-9]{1,1000000}$",
        regexData: {
          showMinField: true,
          minLengthRequired: true,
          minLengthText: "1",
          showMaxField: true,
          maxLengthRequired: true,
          maxLengthText: "1000000",
          alphabetsRequired: false,
          showAlphaField: false,
          numbersRequired: true,
          showNumField: true,
          specialCharRequired: false,
          showSCharField: false,
          specialCharText: null,
        },
        textAlign: "Center",
        show: false,
        field: "zip",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "textarea",
        placeholder: "Address",
        label: "Address",
        width: "200px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "Address",
        derivedValue: "address=addressLine1=addressLine1=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "addressLine2",
        type: "textarea",
        placeholder: "Address Line2",
        label: "Address Line2",
        width: "200px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Address Line2",
        derivedValue: "addressLine2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "addressLine2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "addressLine2",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfCabinets",
        type: "number",
        placeholder: "Number Of Cabinets",
        label: "Number Of Cabinets",
        width: "120px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Number Of Cabinets",
        derivedValue:
          "numberOfCabinets=numberOfCabinets=numberofcabinets=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "numberOfCabinets",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "numberOfCabinets",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "numberOfRacks",
        type: "number",
        placeholder: "Number Of Racks",
        label: "Number Of Racks",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Number Of Racks",
        derivedValue: "numberOfRacks=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "numberOfRacks",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "numberOfRacks",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };
  // submit1 = item => {
  //   let x = facilitiesFields
  //   let objfacilities = {}

  //   objfacilities["_id"] = item[0]["_id"]
  //   for (let x2 of x) {
  //     objfacilities[x2] = item[0][x2]
  //   }
  //   let formFields = this.getFormFields()

  //   formFields = formFields.filter(y => x.includes(y.name))

  //   this.setState({
  //     openNewUserModal: true,
  //     item: objfacilities,
  //     newFormFields: formFields
  //   });
  // }

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();

    // if (type == "submit") {
    // type == "submit"
    //   ?
    // await this.dataTableRef.getDataFromServer(this.state.filterCriteria, "refresh");
    // }

    // : null;
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    // this.setState({
    //   isLoading: true
    // });
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.facilities}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired === "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
          // this.setState({
          //   isLoading: false
          // });
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  return (
    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          editRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          deleteRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          viewRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          exportRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          sample={true}
          PrintRequired={
            rolePermission && rolePermission == "Edit" ? true : false
          }
          // globalSearch={'Display Name/Email'}
          // type='Facilities'
          // apiUrl={apiCalls.Facilities}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },

            // {
            //   'name': 'Block',
            //   "options": [
            //     { 'label': 'Block', 'value': 'Block', 'show': true, "multiple": false, }
            //   ]
            // },
            // {
            //   'name': 'ResetPassword',
            //   "options": [
            //     { 'label': 'ResetPassword', 'value': 'ResetPassword', 'show': true, "multiple": false, }
            //   ]
            // },
            // {
            //   'name': 'Submit',
            //   'action': this.submit,
            //   "options": [
            //     { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false },
            //   ]
            // },
            // {
            //   name: "newModel",
            //   action: this.submit1,
            //   options: [
            //     { label: "newModel", value: "newModel", show: true, multiple: false }
            //   ]
            // }
          ]}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.facilities}
          globalSearch={
            "facilityName/facilityEmail/address/created/updated/country/state/zip/address/addressLine2/numberOfCabinets/numberOfRacks"
          }
          displayName="Facilities"
          type="Facilities"
          routeTo={apiCalls.facilities}
          displayViewOfForm="modal"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.facilities.toLowerCase()}
          apiUrl={apiCalls.facilities}
          selectedId={params.id}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="facilities"
          apiUrl={apiCalls.facilities}
        />
      ) : null}
    </span>
  );
};

export default Facilities;
