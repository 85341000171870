import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CategoryTypes from './components/CategoryTypes';

const categoryTypes = (props,{ t }) => (
  <Container>
    <CategoryTypes {...props} />
  </Container>
);

categoryTypes.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(categoryTypes);
