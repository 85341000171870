import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from 'react'

const DashBoardDataTable = () => {
    const tableFields = [
	{ name: "Facility", title: "Facility", searchKey: "facility", show: true, bodyStyle:"title" },
	{ name: "Cabinet", title: "Cabinet", searchKey: "cabinet", show: true },
	{ name: "Expiration", title: "Expiration", searchKey: "expiration", show: true, bodyStyle: "expiry" },
	{ name: "Missing Items", title: "Missing Items", searchKey: "missingItems", show: true, bodyStyle:"" },
	{ name: "Recalls", title: "Recalls", searchKey: "recalls", show: true },
	{ name: "Date", title: "Date", searchKey: "date", show: true },
    ]

    const results = [
        { facility: "Flipkart", cabinet:"Flipzone-1", expiration:"45 Days", missingItems:134, recalls:10, date:"23-03-2023"},
        { facility: "Amazon", cabinet:"Amazon-1", expiration:"45 Days", missingItems:134, recalls:10, date:"23-03-2023"},
        { facility: "Myntra", cabinet:"Myntra-1", expiration:"45 Days", missingItems:134, recalls:10, date:"23-03-2023"},
        { facility: "Tata Cliq", cabinet:"Tata Cliq-1", expiration:"45 Days", missingItems:134, recalls:10, date:"23-03-2023"},

    ]

    const customBodyStyles = (row, col) => {
        if(col && col.bodyStyle && col.bodyStyle == "expiry")
        {
            return <div className='d-flex align-items-center gap-2'>
				<FontAwesomeIcon icon={["fa", "exclamation-triangle"]} color={"#f59e0b"} />
                <span>{row[col.searchKey]}</span>
                {/* fa-triangle-exclamation */}
            </div>
        }
        if(col && col.bodyStyle && col.bodyStyle == "title"){
            return <span style={{fontWeight:"600"}}>{row[col.searchKey]}</span>
        }
        
        if(col && col.bodyStyle  && col.bodyStyle == "missing"){
            return  <div className='d-flex align-items-center gap-2'>
            <FontAwesomeIcon icon={["fa","cogs"]} color={"#f59e0b"} />
            <span>{row[col.searchKey]}</span>
            </div>
        }
        else{
            return <span>{row[col.searchKey]}</span>
        }
    }
  return (
    <div>
        <DataTable 
        value={ results && results }
        resizableColumns
        columnResizeMode="expand"
        showGridlines
        paginator={false}
        scrollable
        stripedRows
        scrollHeight='75vh'
        rows={10}
        // selection={}
        >
        {
            /**@COLUMNS */
            tableFields.map((column,index) => ( column.show && <Column 
            field={column.searchKey} 
            header= {column.title}
              body={row => customBodyStyles(row,column)}
            />))
        }
        </DataTable>
    </div>
    )
}

export default DashBoardDataTable;