import React, { Component, useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
//config
import config from '../../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(fas);
 const SidebarCategory = (props) => {
  const propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    isNew: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
  };

  const defaultProps = {
    icon: '',
    isNew: false,
  };

  const [collapse,setCollapse] = useState(false);

  

  const toggle = () => {
    setCollapse(!collapse);    
  };


    const {
      title, icon, isNew, children, iconType
    } = props;
    
    const categoryClass = classNames({
      'sidebar__category-wrap': true,
      'sidebar__category-wrap--open': collapse,
    });

    return (
      <div className={categoryClass}>
        <button className="sidebar__link sidebar__category" type="button" onClick={toggle}>

           {(icon && (iconType === 'letters' || !iconType)) ?
        <span
          activeClassName={`active_sidebar__link-icon lnr `}
          className={`sidebar__link-icon lnr `} >{icon}</span>
        : ''}

          {(icon && iconType === 'icon' ) ?
        <span>
          <FontAwesomeIcon icon={["fa", icon]} className="menu-icon" />
        </span>
        : null}

          <p className="sidebar__link-title" style={{paddingLeft:"8px"}}>{title}
            {isNew && <span className="sidebar__category-new" />}
          </p>
          <span className="sidebar__category-icon lnr lnr-chevron-right" />
        </button>
        <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
          <ul className="sidebar__submenu">
            <div>
              {children}
            </div>
          </ul>
        </Collapse>
      </div>
    );
  }


export default SidebarCategory;
