import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CartLists from './components/CartLists';

const cartLists = (props,{ t }) => (
  <Container>
    <CartLists {...props} />
  </Container>
);

cartLists.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(cartLists);
