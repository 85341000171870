import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Mantainances from './components/Mantainances';

const mantainances = (props,{ t }) => (
  <Container>
    <Mantainances {...props} />
  </Container>
);

mantainances.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(mantainances);
