import React from 'react';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CountDisplay from './components/CountDisplay';
import fetch from '../../config/service';
import config from '../../config/config'
import apiCalls from '../../config/apiCalls'
import Loader from '../App/Loader';
import Piechart from '../graphs/Piecharts';
import DataTable from './components/DataTable';


class Dashboard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dashboardData: {},
      isLoading: false,
      expirationsList: [],
      meetingsData:[],
      chartData : [
        { label: 'Facilities', color: '#d81b60', value : 1200 },
        { label: 'Inventories', color: '#39cccc', value : 300},
        { label: 'Facility Staff',color: '#605ca8', value : 600},
        { label: 'Products', color: '#ff851b', value :700}
      ],
      FacilityData : [
        { label: 'Cabinents', color: '#d81b60', value : 100 },
        { label: 'Unfilled Racks', color: '#39cccc', value : 700},
        { label: 'Filled Racks',color: '#605ca8', value : 600},
        { label: 'Damaged Racks', color: '#ff851b', value :200}
      ],
    };
  }
  componentDidMount = () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    if (user) {
      this.setState({
        loginData: user,
        role: user.role
      })
    }
    // this.getExpirationsList();
    this.getCountData();
  }

  // Get dashboard counts
  getCountData() {
    // this.setState({ isLoading: true });
    // fetch('GET',apiCalls.dashboard )
    //   .then(async (response) => {
    //     if (response && response.details) {
    //       this.setState({
    //         dashboardData: response.details,
    //         meetingsData:response.todaymeetings     
    //       });
    //     }
    //     this.setState({ isLoading: false });
    //   }).catch((err) => {
    //     return err;
    //   });
  }

  // Get Last 30 days expirations list
  getExpirationsList = () => {
    this.setState({ isLoading: true });
    fetch('GET', 'locations')
      .then(async (response) => {
        if (response && response.locations) {
          this.setState({
            expirationsList: response.locations
          });
        }
        this.setState({
          isLoading: false,
        });
      }).catch((err) => {
        return err;
      });

  }

  render() {
    return (
      <Container className="dashboard" style={{ width: '98%', marginLeft: '20px' }}>
        <Loader loader={this.state.isLoading} />

        <Row>
          <Col md={12}>
            <h3 className="page-title" style={{ marginBottom: '5px', fontWeight : 600 }}>DashBoard</h3>
          </Col>
        </Row>

        {/* Counts */}
        <CountDisplay
          dashboardData={this.state.dashboardData} 
          meetingsData={this.state.meetingsData}
          />
      <div style={{height:"calc(100vh - 44vh)", overflowY:"scroll", overflowX:"hidden"}}>
        <Row>
          <Col lg={8} xl={8}>
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{'INVENTORY STATUS'}</h5>
                </div>
                <DataTable />
                {/* <Table responsive hover className="table--head-accent table-fixed">
                  <thead>
                    <tr>
                      <th >#</th>
                      <th >{'Facility'}</th>
                      <th>{'Cabinet'}</th>
                      <th >{'Expiration'}</th>
                      <th>{'Missing Items'}</th>
                      <th >{'Recalls'}</th>
                      <th>{'Date'}</th>
                    </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </Table> */}
              </CardBody>
            </Card>
          </Col>

          <Col lg={4} xl={4} >
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{'Track'}</h5>
                </div>
                  <div style={{ width : '80%', margin : 'auto'}}>
                    <Piechart chartOptions={this.state.chartData} />
                  </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
        <Col lg={4} xl={4} >
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{'Facility Status'}</h5>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                  <div style={{ width : '80%', margin : 'auto'}}>
                    <Piechart chartOptions={this.state.FacilityData} />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={8} xl={8}>
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{'INVENTORY STATUS'}</h5>
                </div>
                <DataTable />
                {/* <Table responsive hover className="table--head-accent table-fixed">
                  <thead responsive>
                    <tr>
                      <th >#</th>
                      <th >{'Facility'}</th>
                      <th>{'Cabinet'}</th>
                      <th >{'Expiration'}</th>
                      <th>{'Missing Items'}</th>
                      <th >{'Recalls'}</th>
                      <th>{'Date'}</th>
                    </tr>
                  </thead>
                  <tbody>
                  </tbody>
                </Table> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      </Container >
    );
  }
}

export default withTranslation('common')(Dashboard);