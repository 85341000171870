import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CountDisplay = (props) => {
	let loginRole = localStorage.getItem("loginCredentials");
	loginRole = JSON.parse(loginRole);
	const [count, setCount] = useState({
		facilitiesCount: 10000,
		locationsCount: 14343,
		storageUnitCount : 1654,
		productsCount: 88776,
		companiesCount: 0,
		employeesCount: 0,
		facilityStaffsCount: 134
	});
	const [itemsList] = useState([
		{ displayName: 'Facilities', route: '/facilities', color: '#d81b60', key: 'facilitiesCount',icon : 'layer-group' },
		{ displayName: 'Inventories', route: '/storageUnits', color: '#39cccc', key: 'storageUnitCount', icon : 'warehouse' },
		{ displayName: 'Facility Staff', route: '/facilityStaffs', color: '#605ca8', key: 'facilityStaffsCount', icon : 'users'  },
		{ displayName: 'Products', route: '/products', color: '#ff851b', key: 'productsCount', icon : 'dice'  }
	]);
	const [superAdminItemsList] = useState([
		{ displayName: 'Facilities', route: '/facilitiess', color: '#d81b60', key: 'facilitiesCount', icon : 'layer-group'  },
		{ displayName: 'Inventories', route: '/storageUnitss', color: '#39cccc', key: 'storageUnitCount', icon : 'warehouse'  },
		{ displayName: 'Companies', route: '/companyUsers', color: '#605ca8', key: 'companiesCount', icon : 'users'  },
		{ displayName: 'Employees', route: '/employees', color: '#ff851b', key: 'employeesCount', icon : 'dice'  }
	]);

	useEffect(() => {
		dashBoardCardsCount();
	}, []);

	/**fetch the dashboard display count */
	const dashBoardCardsCount = async () => {
		try {
			// const response = await fetchMethodRequest('GET', apiCalls.dashboard);
			if (false) {
				setCount(response.details);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}

	return (
		<Container className='px-0'>
			<Row>
				{(loginRole.role === 'Super Admin' ? superAdminItemsList : itemsList).map((item, index) => (
					<Col md={12} xl={3} lg={6} xs={12} key={index}>
						<Link to={item.route} style={{ textDecoration: 'none' }}>
							<Card>
								<CardBody className="dashboard__card-widget d-flex justify-content-between" style={{ backgroundColor: item.color }}>
									<div>
										<div className="card__title">
											<h5 className="bold-text text-white">{item.displayName}</h5>
										</div>
										<div className="dashboard__total text-white">
											<TrendingUpIcon className='text-white' />
											<p className="dashboard__total-stat text-white">
												{count?.[item.key]}
											</p>
										</div>
									</div>
									<div className="card-icon">
										<FontAwesomeIcon icon={["fa", item.icon]} className="menu-icon" />
									</div>
								</CardBody>
							</Card>
						</Link>
					</Col>
				))}
			</Row>
		</Container >
	);
}

export default CountDisplay;
