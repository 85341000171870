import React, { useState } from "react";
import { MultiSelect } from 'primereact/multiselect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import fetch from '../../../config/service';
const DoMultiSelectApi = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const optionLabel = props.optionLabel;
    const name = props.name;
    const placeholder = props.placeholder;
    const maxSelectedLabels = props.maxSelectedLabels;
    const searchApi = props.searchApi;
    const searchField = props.searchField;
    const [options, setOptions] = useState([]);
    let field = props.field;

    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };

    useState(() => {
        getOptions();
    },[])

    function getOptions() {

        let filterCriteria = {
            limit: 30,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };
        if(props.item.filterById){
            filterCriteria['criteria'].push({
                "key":props.item.filterIdName,
                "value":props.rowData._id,
                "type":"eq"
            })
        }
        if(props.item.hasDependencyField && props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"]){
            filterCriteria['criteria'].push({
                "key":props.item.fieldForKey,
                "value":props.getValues(props.item.hasDependencyField)["_id"],
                "type":"eq"
            })
        }
        let apiUrl = props.searchApi?.toLowerCase();
        let url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
        console.log(`url`,url);
        return fetch('GET', url)
            .then((response) => {
                console.log('response',response[[apiUrl]]);
                if (response) {
                    if (response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                        setOptions(response[apiUrl]);
                        console.log('optionLabel',optionLabel)
                    }
                }
            }).catch((err) => {
                return err;
            });
    }
    return (
        <div className="flex flex-column">
             <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                <label htmlFor={id} className="text-capitalize">{label}</label>
                {props.markReq && props.markReq === true && markRequired()}
            </div>  
            <MultiSelect
                id={field.name}
                name={name}
                value={field.value}
                options={options}
                onChange={(e) => field.onChange(e.value)}
                optionLabel={optionLabel}
                optionValue='_id'
                placeholder={placeholder}
                filter={true}
                maxSelectedLabels={maxSelectedLabels}
            />
            <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        </div>
    )

}

export default DoMultiSelectApi;